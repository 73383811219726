//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


//import { loadScript } from "vue-plugin-load-script";
export default {
    name: 'ContactUs',

    data: function () {
        let self = this;
        return {
            dictionary: {
                en: {
                    messages: {
                        required: function required(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('required');
                        },
                        email: function email() {
                            return 'Invalid Email Format';
                        },
                        max: function max(fieldName, numbers) {
                            return self.$t(fieldName) + self.$t('max') + " " + numbers + " " + self.$t('numbers');
                        }, min: function min(fieldName, numbers) {

                            return self.$t(fieldName) + " " + self.$t('min') + " " + numbers + " " + self.$t('numbers');
                        },
                        alpha: function alpha_spaces(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('alphaSpaces');
                        },
                        //between: function between(fieldName, n1, n2) {
                        //  return self.$t(fieldName) + ' ' + n1 + ' ' + n2;
                        //},
                        confirmed: function confirmed(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('NotConfirmed');
                        },
                        regex: function regex(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('NotMatchPasswordPattern');
                        },

                        between: function between(fieldName, n) {
                            return self.$t(fieldName) + ' ' + ' Should be Between ' + n[0] + ' and ' + n[1];
                        },
                        numeric: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('should be Number');
                        },
                        verify_password: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('ComplexPassword');
                        },
                        mobileReg: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('Number');
                        }
                    }
                },
                ar: {
                    messages: {
                        required: function required(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('required');
                        },
                        email: function email() {
                            return 'تنسيق بريد إلكتروني غير صالح';
                        },
                        max: function max(fieldName, numbers) {
                            return self.$t(fieldName) + self.$t('max') + numbers + self.$t('numbers');
                        }, min: function min(fieldName, numbers) {

                            return self.$t(fieldName) + " " + self.$t('min') + " " + numbers + " " + self.$t('numbers');
                        },
                        alpha: function alpha_spaces(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('shouldBeCharOnly');
                        },
                        //between: function between(fieldName, n) {
                        //     return self.$t(fieldName) + self.$t('min') + n[0] + self.$t('max') + n[1];
                        //},
                        confirmed: function confirmed(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('NotConfirmed');
                        },
                        regex: function regex(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('NotMatchPasswordPattern');
                        },
                        between: function between(fieldName, n) {
                            return self.$t(fieldName) + ' ' + 'يجب ان يكون بين ' + n[0] + 'و' + n[1];
                        },
                        numeric: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('should be Number');
                        },
                        verify_password: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('ComplexPassword');
                        },
                        mobileReg: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('Number');
                        }
                    }
                }

            },
            model: {},
            showValidMessage: false,
            CityList: [],
            SectionList: [],
            contactInfo: [],
            successmsg: self.$t('savedSucess'),
            warnmesg: self.$t('warnmsg'),
            isActive: true,
            disabled: false
            //rcapt_sig_key: "HERE LIVE GOOGLE API KEY",
            //live_google_recpachid: 0
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        onSubmit: function () {
            alert("Any thing");
            console.log("Any thing");
        },

        upload: function (event) {
            var self = this;
            var file = event.target.files[0];
            var errMsg = null;
            if (!file)
                errMsg = "FileNotFound";
            else if (file.size > 5 * 1024 * 1024)
                errMsg = "LargeFile";
            else if (file.type != 'application/pdf')
                errMsg = "FileMustBePdf";

            if (errMsg) {
                $('#uploader').val(null);
                self.notify(self.$t(errMsg), "warning");
                return;
            }
            self.model.Resume = file;
        },

        notify: function (message, fn = "error") {
            let self = this;
            self.$snotify.clear();
            if (fn == "success") {
                self.$snotify.success(message, {
                    timeout: 3000,
                    showProgressBar: true,
                    //closeOnClick: false,
                    pauseOnHover: true
                });
            }
            else if (fn == "warning") {
                self.$snotify.warning(message, {
                    timeout: 3000,
                    showProgressBar: true,
                    //closeOnClick: false,
                    pauseOnHover: true
                });
            }
            else {
                self.$snotify.html(message, {
                    type: "error",
                    timeout: 3000,
                    showProgressBar: true,
                    //closeOnClick: false,
                    pauseOnHover: true
                });
            }
        },
        onlyNumber($event) {

            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                $event.preventDefault();
            }
        },
        validateModel: function () {
            var self = this;
            var { Name, Email, PhoneNumber, Resume } = self.model
            return Name && Name.length && Email && Email.length && PhoneNumber && PhoneNumber.length && Resume;
        },
        ClearModel: function () {
            var self = this;
            self.model = {};
            self.showValidMessage = false;
            $('#uploader').val(null);
        },
        Submit: function () {
            var self = this;
            debugger;
            var uploadheaders = { "Content-Type": "multipart/form-data" };
            var { Name, Email, PhoneNumber, Resume } = self.model

            var model = new FormData();
            model.append('Files', Resume);
            model.append('Name', Name);
            model.append('Email', Email);
            model.append('PhoneNumber', PhoneNumber);
            self.disabled = true;
            self.axios
                .post(process.env.VUE_APP_APIEndPoint + "JobApplication/Add", model, uploadheaders)
                .then(function (res) {
                    self.notify(self.$t('savedSucess'), "success");
                    self.ClearModel();
                    self.disabled = false;
                    self.scrollToTop();
                });
        },

        save: function () {
            let self = this;
            self.showValidMessage = true;
            self.$validator.validate().then(function (result) {
                if (!result) { ////not valid
                    let detail = $('.form-error').first()[0];
                    if (detail) {
                        detail.scrollIntoView({ behavior: 'smooth', block: 'end' });
                        self.notify(self.$t('warnmsg'), "warning");

                    }
                    return;
                }
                $('.form-error').removeClass("form-error");
                self.Submit();


            });
        },

    },
    created: function () {
        var self = this;

    },
    mounted() {
        let self = this;
        this.$validator.localize(`${self.$i18n.locale}`, self.$i18n.locale == 'en' ? this.dictionary.en : this.dictionary.ar);

    }
};
